export const reports = [
  {
    path: "/reports/general",
    name: "GeneralReports",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Reports/Index.vue"
      ),
    meta: {
      title: "Reports",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/purchase-orders",
    name: "OrdersReports",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Reports/PurchaseOrders.vue"
      ),
    meta: {
      title: "Orders Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/purchase-order-invoice/:reference",
    name: "OrdersInvoice",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Reports/OrderInvoice.vue"
      ),
    meta: {
      title: "Orders Invoice",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/store-status",
    name: "StoreStatus",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Reports/StoreStatus.vue"
      ),
    meta: {
      title: "Store Status Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/receive",
    name: "ReceiveReport",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Reports/Receive.vue"
      ),
    meta: {
      title: "Stock Receing Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/receive/:reference",
    name: "ReceiveVoucher",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Reports/ReceiveVoucher.vue"
      ),
    meta: {
      title: "Receive Voucher",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/transfer",
    name: "TransferReport",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Reports/Transfer.vue"
      ),
    meta: {
      title: "Stock Transfer Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/transfer/items",
    name: "ItemTransferReport",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Reports/ItemTransfer.vue"
      ),
    meta: {
      title: "Items Transfer Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/transfer/voucher/:reference",
    name: "TransferVoucher",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Reports/TransferVoucher.vue"
      ),
    meta: {
      title: "Transfer Voucher",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/purchase/items",
    name: "ItemPurchaseReport",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Reports/ItemPurchase.vue"
      ),
    meta: {
      title: "Items Purchase Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/store/:code/tracker",
    name: "ItemTracker",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Reports/ItemTracker.vue"
      ),
    meta: {
      title: "Item Tracking History",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/recipes-costing",
    name: "RecipesCosting",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Reports/RecipesCosting.vue"
      ),
    meta: {
      title: "Recipes Costing Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/suppliers/balance",
    name: "SupplierBalance",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Reports/SupplierBalance.vue"
      ),
    meta: {
      title: "Supplier Balance Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/spoiled-items",
    name: "SpoiledItemsReport",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Reports/SpoiledItems.vue"
      ),
    meta: {
      title: "Spoiled Items Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/production-sales",
    name: "ProductionSalesReport",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Reports/SalesReport.vue"
      ),
    meta: {
      title: "Sales Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/account/transactions/:reference?",
    name: "AccountTransactions",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Reports/AccountTransactions.vue"
      ),
    meta: {
      title: "Account Transactions Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/accounts/transfers",
    name: "AccountsTransfers",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Reports/AccountTransfers.vue"
      ),
    meta: {
      title: "Accounts Transfers Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/production/sales/items",
    name: "ItemSalesReport",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Reports/ItemSales.vue"
      ),
    meta: {
      title: "Items Sales Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/sales/customers",
    name: "SalesByCustomer",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Reports/CustomerSales.vue"
      ),
    meta: {
      title: "Sales by Customers Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/sales/customers/balance",
    name: "CustomerBalance",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Reports/CustomerBalance.vue"
      ),
    meta: {
      title: "Customers Balance Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/production/produced-items",
    name: "ProducedItemsReport",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Reports/ProducedItems.vue"
      ),
    meta: {
      title: "Produced Items Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/production-stock-status",
    name: "ProductionStoreStatus",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Production/Stock.vue"
      ),
    meta: {
      title: "Production Store Status",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/production/recipes-costing",
    name: "ProductionRecipesCosting",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Production/RecipesCosting.vue"
      ),
    meta: {
      title: "Recipes Costing Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/production/stock/:code/track",
    name: "ProductionItemTracker",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Reports/ItemTracker.vue"
      ),
    meta: {
      title: "Item Movemenets History",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/branches/store-status",
    name: "BranchStoreStatus",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Branches/Stock.vue"
      ),
    meta: {
      title: "Store Status",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/receiving-report",
    name: "BranchReceingReport",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Branches/ReceivesReport.vue"
      ),
    meta: {
      title: "Receiving Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/purchase-report",
    name: "PurchasesReport",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Branches/PurchasesReport.vue"
      ),
    meta: {
      title: "Purchase Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/mise-en-place-report",
    name: "MiseEnPlaceReport",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Reports/MiseEnPlaceReport.vue"
      ),
    meta: {
      title: "Mise En Place Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/branches/sales/:reference?",
    name: "BranchesSalesReport",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Reports/SalesReport.vue"
      ),
    meta: {
      title: "Sales Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/branches/customers/balance",
    name: "BranchCustomerBalance",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Reports/CustomerBalance.vue"
      ),
    meta: {
      title: "Customers Balance Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/branches/sales/items",
    name: "BranchItemSalesReport",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Reports/ItemSales.vue"
      ),
    meta: {
      title: "Items Sales Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/dispatch",
    name: "DispatchReport",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Reports/Dispatch.vue"
      ),
    meta: {
      title: "Dispatch Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/suppliers/payments-made/:reference?",
    name: "SupplierPaymentsMade",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Reports/PaymentsMade.vue"
      ),
    meta: {
      title: "Payments Made",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/sales/payments-received/:reference?",
    name: "PaymentsReceived",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Reports/PaymentsReceived.vue"
      ),
    meta: {
      title: "Payments Received",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/app/activity-logs",
    name: "ActivityLogs",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Reports/ActivityLogs.vue"
      ),
    meta: {
      title: "Activity Logs",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/app/login-activities",
    name: "LoginActivities",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Reports/LoginActivities.vue"
      ),
    meta: {
      title: "Login Activities",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/sales-analysis-report",
    name: "SalesAnalysisReport",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Reports/SalesAnalysisReport.vue"
      ),
    meta: {
      title: "Sales Analysis Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/profit-and-loss",
    name: "ProfitLossReport",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Reports/ProfitLossReport.vue"
      ),
    meta: {
      title: "Profit and Loss Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/stock/consumption",
    name: "ConsumptionReport",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Reports/ConsumptionReport.vue"
      ),
    meta: {
      title: "Consumption Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/stock/returns",
    name: "ReturnsReport",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Reports/ReturnsReport.vue"
      ),
    meta: {
      title: "Returns Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/day-to-day-debts",
    name: "DayToDayDebts",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Reports/DayToDayDebts.vue"
      ),
    meta: {
      title: "Day To Day Debts Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/assets-equipments",
    name: "AssetsListing",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Reports/AssetsListing.vue"
      ),
    meta: {
      title: "Assets and Equipments Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/assets-stock",
    name: "AssetsStock",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Reports/AssetsStock.vue"
      ),
    meta: {
      title: "Assets Stock Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/finance/paid-report",
    name: "PaidAfter",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Reports/PaidAfter.vue"
      ),
    meta: {
      title: "Paid After Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/finance/debts-report",
    name: "DebtsReport",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Reports/DebtsReport.vue"
      ),
    meta: {
      title: "Taken Debts Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/finance/aged-receivables-report",
    name: "PastOverdueInvoices",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Reports/PastOverdueInvoices.vue"
      ),
    meta: {
      title: "Past Due Invoices",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/price-changing",
    name: "PriceChanging",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Reports/PriceChanging.vue"
      ),
    meta: {
      title: "Price Changing Report",
    },
  },
  {
    path: "/reports/balance-sheet-report",
    name: "BalanceSheet",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Reports/BalanceSheet.vue"
      ),
    meta: {
      title: "Balance Sheet Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/general-ledger",
    name: "GeneralLedger",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Reports/GeneralLedger.vue"
      ),
    meta: {
      title: "General Ledger Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/account-type-summary",
    name: "AccountTypeSummary",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Reports/AccountTypeSummary.vue"
      ),
    meta: {
      title: "Account Type Summary Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/journals/:reference?",
    name: "JournalsReport",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Reports/JournalsReport.vue"
      ),
    meta: {
      title: "Journals Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/trial-balance",
    name: "TrialBalance",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Reports/TrialBalance.vue"
      ),
    meta: {
      title: "Trial Balance Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/income-statement",
    name: "IncomeStatement",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Reports/IncomeStatement.vue"
      ),
    meta: {
      title: "Income Statement Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/physical-count-report",
    name: "PhysicalCountReport",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Reports/PhysicalCountReport.vue"
      ),
    meta: {
      title: "Physical Count Report",
      requiresAdminAuth: true,
    },
  },
];
